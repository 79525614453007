import { React, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { deleteTaxonomie, fetchTaxonomies } from "../../../redux/slices/taxonomieSlice";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { heading, endpointName, subHeading} from './Constant'

const List = (props) => {
  const [t,] = useTranslation();
  const dispatch = useDispatch();

  const {
    componentName = "taxonomie",
  } = props;

  const trash = <FontAwesomeIcon icon={faTrash}/>;
  const edit = <FontAwesomeIcon icon={faEdit}/>;
  const plus = <FontAwesomeIcon icon={faPlus} />;
  const search = <FontAwesomeIcon icon={faSearch}/>;

  const taxonomies = useSelector(state => state.taxonomie.taxonomies)
  const page = useSelector(state => state.taxonomie.page)
  const totalItems = useSelector(state => state.taxonomie.totalItems)

  const [searchBox, setSearchBox] = useState(0);
  const[searchData,setSearchData] = useState("")


  useEffect(() => {
    if(searchData){
      dispatch(fetchTaxonomies({endpoint: endpointName[componentName], searchContent:searchData,page:page}))
    }else{
      dispatch(fetchTaxonomies({ endpoint: endpointName[componentName], page: page }))
    }
  }, [dispatch, page, searchData, componentName])

  const deleteTaxonomieClicked = (id) => {
    const doDelete = window.confirm(t(`Are you sure that you want to delete this ${subHeading[componentName]}?`));
    if (doDelete) {
      dispatch(deleteTaxonomie({endpoint: endpointName[componentName], id: id}))
    }
  };

  const searchHandler = () => {
    setSearchBox(searchBox === 0 ? 1 : 0);
    setSearchData("");
  };

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3"></div>
            <div className="col-6">
              <h4 className="mb-0">{t(heading[componentName])}</h4>
            </div>
            <div className="col-3 text-right">
              <button type="button" className="btn btn-sm mr-2" onClick={searchHandler}>{search}</button>
              <Link to={`/taxonomies/${componentName}/create`}>
                <button className="btn btn-sm plus-button">{plus}</button>
              </Link>
            </div>
          </div>
        </div>
        { searchBox !== 0 &&
          <div className={`col-12 searchbox ${searchBox && "py-2"} fade-in-left`} style={{height: searchBox && "auto", transform: `scale(${searchBox})`, transition: "transform 0.5s ease"}}>
            <input type="text" className="w-100 background-white" value={searchData} onChange={(e) => setSearchData(e.target.value)} placeholder={t("Zoeken")} />
          </div>
        }
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>{(["Name","Action"]).map((h) => (
                <th key={h}>{t(h)}</th>
              ))}</tr>
            </thead>
            <tbody>
              {(taxonomies || []).map((taxonomie) => (
                <tr key={subHeading[componentName] + taxonomie.id}>
                  <td>{taxonomie?.name}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger ml-auto"
                      onClick={() => deleteTaxonomieClicked(taxonomie.id)}
                    >
                      {trash}
                    </button>
                    <Link to={`/taxonomies/${componentName}/edit/${taxonomie.id}`}>
                      <button
                        className="btn btn-sm btn-secondary ml-1"
                      >
                        {edit}
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="cardPagination">
            <Pagination
              activePage={page}
              itemClass="page-item"
              linkClass="page-link"
              itemsCountPerPage={10}
              totalItemsCount={totalItems}
              pageRangeDisplayed={3}
              onChange={(page) => dispatch(fetchTaxonomies({ endpoint: endpointName[componentName], page: page }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
