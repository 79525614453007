import {React, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next"
import { fetchAllTaxonomies, fetchLessonsWithTaxonomies, fetchLessonsWithTaxonomy, setLessonsWithTaxonomySearch } from "../../../redux/slices/taxonomieSlice";
import CanvasJSReact from "../../../js/canvasjs.react";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Reports = () => {
  const [t,] = useTranslation();
  const dispatch = useDispatch();

  const lessonsWithTaxonomies = useSelector((state) => state.taxonomie?.lessonsWithTaxonomies);
  const taxonomyTypes = useSelector((state) => state.taxonomie?.allTaxonomies);
  const lessonsWithTaxonomy = useSelector((state) => state.taxonomie?.lessonsWithTaxonomy);
  const lessonsWithTaxonomySearch = useSelector((state) => state.taxonomie?.lessonsWithTaxonomySearch);

  useEffect(() => {
    dispatch(fetchLessonsWithTaxonomies());
    dispatch(fetchAllTaxonomies());
  }, [dispatch]);


  useEffect(() => {
    if(lessonsWithTaxonomySearch?.taxonomyType && lessonsWithTaxonomySearch?.startDate && lessonsWithTaxonomySearch?.endDate) {
      // const data = {
      //   taxonomyType: lessonsWithTaxonomySearch.taxonomyType,
      //   startDate: lessonsWithTaxonomySearch.startDate,
      //   endDate: lessonsWithTaxonomySearch.endDate
      // }
      dispatch(fetchLessonsWithTaxonomy(lessonsWithTaxonomySearch))
    }
  }, [dispatch, lessonsWithTaxonomySearch])
  

  const options = {
        axisX:{
            labelAngle: -60
          },
        data: [
            {
                type: "column",
                dataPoints: lessonsWithTaxonomies ? Object.keys(lessonsWithTaxonomies).map((key, index) => {
                    return { label: t(key), y: lessonsWithTaxonomies[key] }
                }) : []
            }
        ]
    }

  const lineChart = {
    animationEnabled: true,	
    axisY : {
      title: t("Number of Lessons")
    },
    toolTip: {
      shared: true
    },
    data: lessonsWithTaxonomy ? Object.keys(lessonsWithTaxonomy).map((key, index) => {
        return { 
          type: "line",
          name: key,
          showInLegend: true,
          dataPoints: lessonsWithTaxonomy[key] ? Object.keys(lessonsWithTaxonomy[key]).map((k, i) => {
            return { y: lessonsWithTaxonomy[key][k], label: k}
          }) : []
        }
    }) : []
  }
  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Reports')}</h4>
            </div>
            <div className="col-3 text-right">

            </div>
          </div>
        </div>
        <div className="card-body px-3">
          <div className="row">
            <div className="col-12">
              <div className="mb-5">
                <h4>{t("The total use of taxonomies in lessons")}</h4>
                <CanvasJSChart
                    options={options}
                    // onRef = {ref => this.chart = ref}
                />
              </div>

              <div className="mb-5">
                <h4>{t("The total number of lessons per taxonomy")}</h4>
                <div className="row mb-2">
                  <div className="col-4">
                    <select
                      id="taxonomyType"
                      className="form-control "
                      name="taxonomyType"
                      value={lessonsWithTaxonomySearch?.taxonomyType}
                      onChange={(e) => dispatch(setLessonsWithTaxonomySearch({...lessonsWithTaxonomySearch, taxonomyType: e.target.value }))}
                    >
                      <option value={false}>{t("Type")}</option>
                      { Object.keys(taxonomyTypes).map((key, index) => {
                        return (
                          <option value={key}>{t(key)}</option>
                        )
                      })}
                    </select>
                  </div>
                  
                  <div className="col-4">
                    <input
                      id="startDate"
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={lessonsWithTaxonomySearch?.startDate}
                      onChange={(e) => dispatch(setLessonsWithTaxonomySearch({...lessonsWithTaxonomySearch, startDate: e.target.value }))}
                      required
                    />
                  </div>

                  <div className="col-4">
                    <input
                      id="endDate"
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={lessonsWithTaxonomySearch?.endDate}
                      onChange={(e) => dispatch(setLessonsWithTaxonomySearch({...lessonsWithTaxonomySearch, endDate: e.target.value }))}
                      required
                    />
                  </div>
                </div>
                <CanvasJSChart options = {lineChart} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );



}
export default Reports;
