import {createAction, createSlice} from '@reduxjs/toolkit'
const initialState = {
    loader:false,
    classes: [],
    archive: false,
    selectedClass: {},
    page: 1,
    totalItems: 0,
}

export const classSlice = createSlice({
    name: 'class',
    initialState,
    reducers: {
        classSelected: (state, action) => {
            state.selectedClass = action.payload
        },
        selectionCleared: (state) => {
            state.selectedClass = {}
        },
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        classDeleted: (state, action) => {
            state.classes = state.classes.filter(school => school.id !== action.payload)
        },
        classesFetched: (state, action) => {
            state.classes = action.payload
        },
        switchArchive: (state, action) => {
            state.archive = action.payload
            
        },
        loadingStopped:(state)=>{
            state.loader=false
        },
        loadingStarted:(state)=>{
            state.loader=true
        }
    }
})

export const {
    switchArchive,
    classSelected,
    selectionCleared,
    classesFetched,
    pageChanged,
    totalItemsChanged,
    classDeleted,
    loadingStopped,
    loadingStarted
} = classSlice.actions

export const createClass = createAction('class/create')
export const updateClass = createAction('class/update')
// please put the school id in the payload
export const fetchClasses = createAction('class/fetch-of-school')
// please put the class id in the payload
export const selectClass = createAction('class/select')
export const deleteClass = createAction('class/delete')
export const addClassMentor = createAction('class/add-mentor');
export const Setloader = createAction('class/loader');



export default classSlice.reducer
