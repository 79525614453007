import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { downloadPortfolio, fetchPupilDetails, fetchPupilsPortfolioItem } from "../../redux/slices/pupilSlice";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faThLarge, faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Filters from "../../component/Filters/Filters";
import { fetchPupilsLessons, resetPortFolio } from "../../redux/slices/pupilSlice";
import LessonList from "./LessonList";


const PupilList = ({ id }) => {
  const [t,] = useTranslation();
  const dispatch = useDispatch();
  const portFolio = useSelector(state => state.pupil?.portFolio) || []
  const page = useSelector(state => state.pupil?.portFolioPage)
  const pupilId = useSelector(state => state.auth?.user.pupil?.id)
  const plus = <FontAwesomeIcon icon={faPlus} />;
  const totalItems = useSelector(state => state.pupil?.portFolioTotalItems)
  const search = <FontAwesomeIcon icon={faSearch} />;
  const cardView = <FontAwesomeIcon icon={faThLarge} />;
  const listView = <FontAwesomeIcon icon={faList} />;
  const [cardChange, setCardChange] = useState(true)
  const [searchBox, setSearchBox] = useState(0)
  const [searchData, setSearchData] = useState("")

  useEffect(() => {
    dispatch(resetPortFolio());
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchPupilDetails(id ? id : pupilId));
    dispatch(fetchPupilsPortfolioItem({ pupilId: id ? id : pupilId, searchContent: searchData, page: page }))
  }, [id, page, dispatch, pupilId, searchData])
  const menuData = (
    <div className="row">
      <div className="col-12">
        <p className="font-weight-bold my-3  pt-2 menu-heading">
          {t("NEW")}
        </p>
      </div>
      <div className="col-12 mb-5">
        <Link to="/portfolio-create">
          <div className="card">
            <div className="text-center mt-5 mb-3 py-2">
              <button className="btn">
                {plus}
              </button>
            </div>
            <div className="card-body mb-4 py-2">
              <p className="card-title mb-2 text-center">
                {t("Create a new portfolio")}
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
  const cardViewHandler = () => {
    setCardChange(true);
  }
  const listViewHandler = () => {
    setCardChange(false);
  }
  const searchHandler = () => {
    setSearchBox(searchBox === 0 ? 1 : 0);
    setSearchData("");
  }
  const downloadPdf = () => {
    dispatch(downloadPortfolio(pupilId));
    
  }
  return (
    <div className="row mt-4 color-grayish">
      <div className="col-10 offset-1">
        <div className="row">
          <div className="col-md-4 col-12">
            <Filters searchClickCall={fetchPupilsLessons({ pupilId: id ? id : pupilId, searchContent: searchData, page: page })} />
            <div className="row">
              <Link to={`${id ? id : pupilId}/portfolio-items/images`}>
                <button className="btn all-photos py-2 mt-5 ml-3">
                  {t("Alle foto's")}
                </button>
              </Link>
            </div>
            {/* <div className="row">
              <button className="btn all-photos py-2 mt-3 ml-3">
                {t("Alle video's")}
              </button>
            </div> */}
            {menuData}
            <div className="row">
              <button className="btn all-photos py-2 mt-3 ml-3" onClick={downloadPdf}>
                {t("Download")}
              </button>
            </div>
          </div>
          <div className="col-md-8 col-12 pb-5">
            <div className="row">
              <LessonList pupilId={id ? id : pupilId} />
              <div className="col-12 p-2">
                <div className="row mt-5">
                  <div className="col-6 font-weight-bold lessons-heading mb-0">{t('PORTFOLIO')}</div>
                  <div className="col-6">
                    <div className="text-right mt-3">
                      <button type="button" className="btn mr-2" style={{ fontSize: "24px" }} onClick={searchHandler}>{search}</button>
                      <button type="button" className="btn mr-2" onClick={listViewHandler} style={{ fontSize: "24px", color: "white", background: "#f47d44", borderRadius: ".4rem" }}>{listView}</button>
                      <button type="button" className="btn" style={{ fontSize: "24px", color: "white", background: "#f47d44", borderRadius: ".4rem" }} onClick={cardViewHandler}>{cardView}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-12 ${searchBox && "py-2 pr-0"}`} style={{ height: searchBox && "auto", transform: `scale(${searchBox})`, transition: "transform 0.5s ease" }}>
                <input type="text" className="w-100 background-white" value={searchData} onChange={(e) => setSearchData(e.target.value)} placeholder={t('Zoeken')} />
              </div>
              
              {(portFolio ?? []).map((pupil) => {
                return cardChange ? <div className="col-6 py-2 pr-0 d-flex" key={pupil?.id}>
                  <div className="lessons grid w-100">
                    <div className="lessons-image" style={{ backgroundImage: (pupil?.image ? `url("${pupil?.image_url}")` : `url("images/placeholder-image.png")`) }} > </div>
                    <div className="lesson-card p-3">
                      <div className="pt-4">{t('Portfolio')}</div>
                      <div className="pt-1"><strong>{pupil?.name}</strong></div>
                      <div className="d-flex justify-content-end"><Link to={`/portfolio-details/${pupil.id}`} className="btn btn-link">{t('view more')}</Link></div>
                    </div>
                  </div>
                </div> : <div className="col-12 py-2 pl-0 d-flex justify-content-end pr-3" key={pupil?.id}>
                  <div className="row lessons list w-100">
                    <div className="lesson-card col-6">
                      <div className="lessons-image" style={{ backgroundImage: (pupil?.image ? `url("${pupil?.image_url}")` : `url("images/placeholder-image.png")`) }} > </div>
                    </div>
                    <div className="lesson-card col-6">
                      <div className="pt-4">{t('Portfolio')}</div>

                      <div className="pt-1"><strong>{pupil?.name}</strong></div>
                      <div className="d-flex justify-content-end"><Link to={`/portfolio-details/${pupil.id}`} className="btn btn-link">{t('view more')}</Link></div>
                    </div>
                  </div>
                </div>
              })
              }
            </div>
            
            {totalItems ?
              (totalItems > 3 ? <div className="cardPagination d-flex justify-content-center mt-4">
                <Pagination
                  activePage={page}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemsCountPerPage={4}
                  totalItemsCount={totalItems}
                  pageRangeDisplayed={3}
                  onChange={(page) => dispatch(fetchPupilsPortfolioItem({ pupilId: id ? id : pupilId, searchContent: searchData, page: page }))}
                />
              </div> : "")
              : (<p className="noLessonFound mt-4">{t("No portfolio found")}</p>)
            }
             
          </div>
        </div>
      </div>

    </div>
  );
}

export default PupilList;
