import {React, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faLink,
  faArrowLeft,
  faUnlink,
  faInfoCircle,
  faPlus,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import {selectSchool} from "../../redux/slices/schoolSlice";
import {Link} from "react-router-dom";
import {deletePupil, fetchPupils} from "../../redux/slices/pupilSlice";
import {fetchTeachers} from "../../redux/slices/teacherSlice"
import { selectClass } from "../../redux/slices/classSlice";
import {formatDate} from "../../i18n/i18n";
import { Dialog } from "../../component";
import ClassMentorModal from './ClassMentorModal';
import EditPupilModal from './EditPupilModal';

const ClassInfo = ({schoolId, classId}) => {
  const [t,] = useTranslation();
  const dispatch = useDispatch();
  const link = <FontAwesomeIcon icon={faLink}/>;
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const info = <FontAwesomeIcon icon={faInfoCircle} />;
  const unlink = <FontAwesomeIcon icon={faUnlink} />;
  const plus = <FontAwesomeIcon icon={faPlus} />;
  const edit = <FontAwesomeIcon icon={faEdit} />;

  const selectedSchool = useSelector(state => state.school.selectedSchool)
  const selectedClass = useSelector(state => state.class.selectedClass)
  const pupils = useSelector(state => state.pupil.pupils);
  const teacher = useSelector(state => state.teacher.teachers);
  const [showClassMentorModal, setShowClassMentorModal] = useState("");
  const [showEditPupilModal, setShowEditPupilModal] = useState("");
  const [selectedPupil, setSelectedPupil] = useState("");

  let endDate
  let archive = false;
  try {
    endDate = new Date(selectedClass?.endDate + 'T00:00:00')
    archive = (endDate < new Date());
  } catch (e) {
    endDate = selectedClass?.endDate
  }

  useEffect(() => {
    dispatch(selectSchool(schoolId))
    dispatch(selectClass({schoolId: schoolId, classId: classId}))
    dispatch(fetchTeachers({schoolId: schoolId, classId: classId}))
    dispatch(fetchPupils({schoolId: schoolId, classId: classId}))
    dispatch(fetchTeachers({schoolId: schoolId, classId: classId}))
  }, [dispatch, schoolId, classId]);

  const unlinkPupilClicked = (pupilId) => {
    const doDelete = window.confirm(t("Are you sure that you want to unlink this pupil?"));
    if (doDelete) {
      dispatch(deletePupil({schoolId: schoolId, classId: classId, pupilId: pupilId}))
    }
  };

  const getPupilModal = pupil => {
    setSelectedPupil(pupil);
    setShowEditPupilModal(true);
  }

  return (
    <div className="container padding-80">
      <Dialog
        isVisible={showClassMentorModal}
        setIsVisible={setShowClassMentorModal}
        size={"md"}
      >
        <ClassMentorModal
          setIsVisible={setShowClassMentorModal}
          teacher={teacher}
          schoolId={schoolId}
          classId={classId}
        />
      </Dialog>
      <Dialog
        isVisible={showEditPupilModal}
        setIsVisible={setShowEditPupilModal}
        size={"md"}
      >
        <EditPupilModal
          setIsVisible={setShowEditPupilModal}
          pupil={selectedPupil}
          schoolId={schoolId}
          classId={classId}
        />
      </Dialog>
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to={`/schools/${schoolId}`}>
                <button className="btn btn-sm plus-button">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{selectedClass.name}{archive ? ' (' + t('Archive') + ')' : ''}</h4>
            </div>
            <div className="col-3 text-right">
                <button onClick={() => setShowClassMentorModal(true)} className="btn btn-sm plus-button">{plus}</button>
            </div>
          </div>
        </div>
        <div className="card-body px-5">
          <div className="row">
            <div className="col-6">
              <div className="card_view h-100">
              <div className="class-info">
                  <p className="mb-2 add_details">{selectedClass.name}</p>
                  <p className="mb-2 add_details">{formatDate(selectedClass.startDate)} {t('till')} {formatDate(selectedClass.endDate)}</p>
                  <p className="mb-2 add_details">{(selectedClass.mentors || []).map( (mentor, index) => { return ( <span className="add_details">{mentor.user.name}{ index === selectedClass.mentors.length - 1 ? "" : ', ' }</span>)})}</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card_view">
                <div className="school-info">
                  <p className="mb-2 add_details">{selectedSchool.name}</p>
                  <p className="mb-2 add_details">{selectedSchool.street} {selectedSchool.houseNumber} {selectedSchool.houseNumberSuffix}</p>
                  <p className="mb-2 add_details">{selectedSchool.postcode}</p>
                  <p className="mb-2 add_details">{selectedSchool.city}</p>
                  <p className="mb-2 add_details">{selectedSchool.phone}</p>
                  <p className="mb-2 add_details">{selectedSchool.websiteUrl}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3"></div>
            <div className="col-6">
              <h4 className="mb-0">{t('Teacher List')}</h4>
            </div>
            <div className="col-3 text-right">
              {archive ? <button className="disabled btn btn-sm plus-button">{link}</button> :
                  (<Link
                  to={`/schools/${schoolId}/classes/${classId}/teachers/attach`}>
                  <button className="btn btn-sm plus-button">{link}</button>
                </Link>)}
            </div>
          </div>
        </div>
        <div className="card-body px-5">
          {teacher?.length ?
          <table className="table table-hover">
            <thead>
              <tr>{(['Name', 'Email', 'Actions']).map((h) => (
                <th key={h}>{t(h)}</th>
              ))}</tr>
            </thead>
            <tbody>
            {(teacher || []).map((teacher) => (
              <tr key={'teacher-' + teacher.id}>
                <td>{teacher.user?.name}</td>
                <td>{teacher.user?.email}</td>
                <td>
                  <button className="btn btn-sm btn-danger ml-auto"
                  // onClick={() => unlinkPupilClicked(pupil.id)}
                  >
                    {unlink}
                  </button>

                </td>
              </tr>
              ))}
            </tbody>
          </table>
          :
          <p>{t('No teacher found')}</p>
          }
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3"></div>
            <div className="col-6">
              <h4 className="mb-0">{t('Pupil List')}</h4>
            </div>
            <div className="col-3 text-right">
              {archive ? <button className="disabled btn btn-sm plus-button">{link}</button> :
                (<Link to={`/schools/${schoolId}/classes/${classId}/pupils/attach` }>
                  <button className="btn btn-sm plus-button">{link}</button>
                </Link>)}
            </div>
          </div>
        </div>
        <div className="card-body px-5">
          {pupils?.length ?
          <table className="table table-hover">
            <thead>
              <tr>{(['Name', 'Referentie', 'Email', 'Actions']).map((h) => (
                <th key={h}>{t(h)}</th>
              ))}</tr>
            </thead>
            <tbody>
            {(pupils || []).map((pupil) => (
              <tr key={'pupil-' + pupil.id}>
                <td>{pupil.deleted_from_class && (
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t('Deleted at') + ' ' + formatDate(pupil.deleted_from_class)}
                  >
                    {info}&nbsp;&nbsp;
                  </span>
                )}{pupil.user?.name}</td>
                <td>{pupil.reference}</td>
                <td>{pupil.user?.email}</td>
                <td style={{"display": "inherit"}}>
                  <button onClick={() => getPupilModal(pupil)} className="btn btn-sm btn-primary mr-1">
                    {edit}
                  </button>
                  {
                  pupil.deleted_from_class ? (
                    <button className="btn btn-sm btn-danger ml-auto disabled" >
                     {unlink}
                    </button>) : (<button className="btn btn-sm btn-danger ml-auto" onClick={() => unlinkPupilClicked(pupil.id)} >
                      {unlink}
                    </button>)
                    }
                </td>
              </tr>
              ))}
            </tbody>
          </table>
          :
          <p>{t('No pupils found')}</p>
          }
        </div>
      </div>
    </div>
  );



}
export default ClassInfo;
