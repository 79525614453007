import React, {useEffect, useState} from "react";
import {Button} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch,} from "react-redux";
import {fetchPupils} from "../../redux/slices/pupilSlice"
import {ProgressBar, Step} from "react-step-progress-bar";

function CreateLessonStep3({
                             setStep,
                             classId,
                             schoolId,
                             selectClassPupils,
                             setSelectClassPupils,
                             handleSubmit
                           }) {
  const Pupils = useSelector(state => state.pupil.pupils);
  const [allchecked, setAllchecked] = useState(true)
  const [t,] = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPupils({classId, schoolId}))
  }, [dispatch, classId, schoolId])

  const isChecked = (id) => selectClassPupils.filter(pupil => pupil.id === id).length > 0

  useEffect(() => {
    if (Pupils && selectClassPupils && Pupils.length === selectClassPupils.length) {
      setAllchecked(true);
    } else {
      setAllchecked(false);
    }
  }, [selectClassPupils, Pupils])


  const setPupilById = (id, checked) => {
    const currentPupil = Pupils.filter(pupil => pupil.id === id)[0];
    let selectedPupilsWithoutCurrent = selectClassPupils.filter(pupil => pupil.id !== id);
    if (checked) {
      if (currentPupil) {
        setSelectClassPupils([...selectedPupilsWithoutCurrent, currentPupil])
      }
      if (id === "checkAll") {
        setSelectClassPupils(Pupils);
      }
    } else {
      setSelectClassPupils(selectedPupilsWithoutCurrent)
      if (Pupils.length !== selectClassPupils.length) {
        setAllchecked(false);
      }
      if (id === "checkAll" && allchecked) {
        setSelectClassPupils([])
      }
    }
  }

  return (
    <div className="mt-5">
      <>
        <h4 className="modal-title d-flex justify-content-center mb-4">{t('Nieuwe les maken')}</h4>
        <ProgressBar percent={100}>
          <Step>{({accomplished, index}) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
          <Step>{({accomplished, index}) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
          <Step>{({accomplished, index}) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
        </ProgressBar>
      </>

      <p
        className="d-flex justify-content-center mt-4"> {t('A list with all pupils that belong to the selected class')} </p>
      <div className="row mt-4">
        {(Pupils || []).map((pupil) => (
          <div className="col-4" key={pupil.id}>
            {pupil && !pupil.deleted_from_class && <div
              className="w-100  d-flex justify-content-center align-items-center">
              <input className="mr-2" type="checkbox" id={pupil.id}
                     value={pupil.id}
                     checked={isChecked(pupil.id)}
                     onChange={(e) => {
                       setPupilById(pupil.id, e.target.checked)
                     }}/>
              <label className="mb-0"
                     htmlFor={pupil.id}>{pupil.user?.name}</label>
            </div>}
          </div>
        ))}

        {Pupils?.length > 0 && Pupils.filter(e => e.deleted_from_class === '' || e.deleted_from_class === null ).length > 0 &&
          <div className="w-100 mt-5">
            <input className="mr-2" type="checkbox" id="selectAll"
                   value={allchecked}
                   checked={allchecked}
                   onChange={(e) => {
                     setPupilById('checkAll', e.target.checked)
                   }}/>
            <label className="mb-0"
                   htmlFor="selectAll">{t(allchecked ? 'Deselect all' : 'Select all')}</label>
          </div>
        }
      </div>
      <div className="mb-5"/>
      <div className="d-flex justify-content-around mb-5 mt-3">
        <Button variant="outline-primary"
          onClick={() => setStep(2)}>
            {t('Back')}
        </Button>
        <Button
          disabled={!Pupils?.length || !selectClassPupils.length} onClick={() => handleSubmit()}
          >
            {t('Submit')}
        </Button>
      </div>
    </div>
  )
}

export default CreateLessonStep3;
