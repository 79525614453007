import {call, put, takeEvery, select} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import Api from "../lib/Api";
import {
  createSchool,
  deleteSchool,
  fetchSchools,
  fetchAllSchools,
  pageChanged,
  schoolDeleted,
  schoolSelected,
  schoolsFetched,
  allSchoolsFetched,
  selectSchool,
  totalItemsChanged,
  updateSchool
} from "../redux/slices/schoolSlice";
import {showNotification} from "../redux/slices/appSlice";
import i18n from "../i18n/i18n";

function* fetchSchoolsSaga(action) {
  let response = false;
  const {searchContent, page} = action.payload;
  if (searchContent) {
    response = yield call(Api.get, `/api/schools?page=${page}&search=${searchContent}`)
  } else {
    response = yield call(Api.get, `/api/schools?page=${page}`)
  }
  if (response?.data?.data) {
    yield put(schoolsFetched(response.data.data || []))
    yield put(pageChanged(response.data.current_page))
    yield put(totalItemsChanged(response.data.total))
  } else {
    yield put(showNotification([i18n.t('Unfortunately there was an error fetching the schools from the server.'),"error"]))
  }
}

function* fetchAllSchoolsSaga(action) {
  const response = yield call(Api.get, "/api/schools")

  if (response?.data) {
    yield put(allSchoolsFetched(response.data || []))
  } else {
    yield put(allSchoolsFetched([]))
  }
}

function* deleteSchoolSaga(action) {
  const response = yield call(Api.delete, `/api/schools/${action.payload}`)

  if (response.response) {
    yield put(showNotification([i18n.t('Deleting school has not succeeded.'),"error"]))
  } else {
    yield put(schoolDeleted(action.payload))
    const page = yield select(state => state.school.page)
    yield put(fetchSchools({page}))
  }
}

function* createSchoolSaga(action) {
  const response = yield call(Api.post, '/api/schools', action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Creating school has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push('/schools'))
  }
}

function* updateSchoolSaga(action) {
  const id = yield select(state => state.school.selectedSchool.id)
  const response = yield call(Api.put, `/api/schools/${id}`, action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Creating school has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push('/schools'))
  }
}

function* selectSchoolSaga(action) {
  const response = yield call(Api.get, `/api/schools/${action.payload}`)
  if (response?.data) {
    const schoolData = {
      id: response.data.id,
      name: response.data.name,
      street: response.data.street,
      houseNumber: response.data.house_number,
      houseNumberSuffix: response.data.house_number_suffix,
      phoneNumber: response.data.phone_number,
      city: response.data.city,
      postcode: response.data.postcode,
      websiteUrl: response.data.website_url,
      active: response.data.active === 1 ? true : false
    };

    yield put(schoolSelected(schoolData))
  } else {
    yield put(showNotification(Object.values(response.data)))
  }
}

function* schoolSaga() {
  yield takeEvery(fetchSchools.type, fetchSchoolsSaga)
  yield takeEvery(fetchAllSchools.type, fetchAllSchoolsSaga)
  yield takeEvery(deleteSchool.type, deleteSchoolSaga)
  yield takeEvery(createSchool.type, createSchoolSaga)
  yield takeEvery(updateSchool.type, updateSchoolSaga)
  yield takeEvery(selectSchool.type, selectSchoolSaga)
}

export default schoolSaga
