import {call, put, takeEvery, select} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import Api from "../lib/Api";
import {
  classesFetched,
  createClass,
  fetchClasses,
  selectClass,
  updateClass,
  deleteClass,
  classSelected,
  switchArchive,
  addClassMentor,
  loadingStarted,
  loadingStopped
} from "../redux/slices/classSlice";
import {showNotification} from "../redux/slices/appSlice";
import i18n from "../i18n/i18n";

function* createClassSaga(action) {
  const response = yield call(Api.post, '/api/classes', action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Creating class has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push('/schools/' + action.payload.school_id))
  }
}

function* updateClassSaga(action) {
  const id = yield select(state => state.class.selectedClass.id)
  const response = yield call(Api.put, `/api/classes/${id}`, action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Updating class has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push('/schools/' + action.payload.school_id))
  }
}

function* fetchClassesSaga(action) {
  yield put(loadingStarted());
  const archive = yield select(state => state.class.archive)
  const query = archive ? '?archive=1': '';
  const response = yield call(Api.get, `/api/schools/${action.payload}/classes${query}`);
  if (response.status === 500) {
    yield put(showNotification([i18n.t('Error fetching classes'),"error"]))
  } else if (response.status === 422) {
    let errors = Object.values(response.data?.errors || []).reduce((errors, fieldErrors) => [...errors, ...fieldErrors]);
    yield put(showNotification(errors))
  } else {
    yield put(classesFetched(response.data))
  }
  yield put(loadingStopped());
}

function* selectClassSaga(action) {
  const response = yield call(Api.get, `/api/schools/${action.payload.schoolId}/classes/${action.payload.classId}`)
  if (response?.data) {
    const classData = {
      id: response.data.id,
      name: response.data.name,
      startDate: response.data.start_date,
      endDate: response.data.end_date,
      mentors: response.data.mentors
    };
    yield put(classSelected(classData))
  } else {
    yield put(showNotification(Object.values(response.data)))
  }
}

function* deleteClassSaga(action) {
  const response = yield call(Api.delete, `/api/classes/${action.payload.classId}`)
  if (response.response) {
    yield put(showNotification([i18n.t('Deleting class has not succeeded.'),"error"]))
  } else {
    yield put(fetchClasses(action.payload.schoolId))
  }
}

function* switchArchiveSaga(action) {
  const schoolId = yield select(state => state.school.selectedSchool.id)
  yield put(fetchClasses(schoolId))
}

function* addClassMentorSaga(action){
  const response = yield call(Api.post, `/api/classes/${action.payload.classId}/mentor`, action.payload.formData)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Adding mentor for class has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push(`/schools/${action.payload.schoolId}/classes/${action.payload.classId}`))
  }
}



function* classSaga() {
  yield takeEvery(createClass.type, createClassSaga)
  yield takeEvery(updateClass.type, updateClassSaga)
  yield takeEvery(fetchClasses.type, fetchClassesSaga)
  yield takeEvery(selectClass.type, selectClassSaga)
  yield takeEvery(deleteClass.type, deleteClassSaga)
  yield takeEvery(switchArchive.type, switchArchiveSaga)
  yield takeEvery(addClassMentor.type, addClassMentorSaga)
}

export default classSaga
