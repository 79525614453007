import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPupilsLessons, resetPupilsLessons } from "../../redux/slices/pupilSlice";
import { lessonsFilter, resetDetails } from "../../redux/slices/lessonSlice";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faThLarge, faList } from "@fortawesome/free-solid-svg-icons";
import Ribbon from "../../component/Lesson/Ribbon";
import Loader from "../../component/Loader";
const LessonList = (props) => {
    const [t,] = useTranslation();
    const dispatch = useDispatch();
    const lessons = useSelector(state => state.lesson?.lessons)
    const loader= useSelector(state => state.pupil.loader)
    const page = useSelector(state => state.lesson?.page)
    const totalItems = useSelector(state => state.lesson?.totalItems)
    const search = <FontAwesomeIcon icon={faSearch} />;
    const cardView = <FontAwesomeIcon icon={faThLarge} />;
    const listView = <FontAwesomeIcon icon={faList} />;
    const [cardChange, setCardChange] = useState(true)
    const [searchBox, setSearchBox] = useState(0)
    const [searchData, setSearchData] = useState("")
    useEffect(() => {
        dispatch(resetPupilsLessons());
        dispatch(lessonsFilter(""));
        dispatch(resetDetails())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchPupilsLessons({ pupilId: props.pupilId, searchContent: searchData, page: page }))
    }, [page, dispatch, searchData, props.pupilId])

    const cardViewHandler = () => {
        setCardChange(true);
    }
    const listViewHandler = () => {
        setCardChange(false);
    }
    const searchHandler = () => {
        setSearchBox(searchBox === 0 ? 1 : 0);
        setSearchData("");
    }
    return (
        <div className="col-12">

           <div className="row">
                <div className="col-12 p-2">
                    <div className="row mt-5">
                        <div className="col-6 font-weight-bold lessons-heading mb-0">{t('LESSONS')}</div>
                        <div className="col-6">
                            <div className="text-right mt-3">
                                <button type="button" className="btn mr-2" style={{ fontSize: "24px" }} onClick={searchHandler}>{search}</button>
                                <button type="button" className="btn mr-2" onClick={listViewHandler} style={{ fontSize: "24px", color: "white", background: "#f47d44", borderRadius: ".4rem" }}>{listView}</button>
                                <button type="button" className="btn" style={{ fontSize: "24px", color: "white", background: "#f47d44", borderRadius: ".4rem" }} onClick={cardViewHandler}>{cardView}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-12 ${searchBox && "py-2 pr-0"}`} style={{ height: searchBox && "auto", transform: `scale(${searchBox})`, transition: "transform 0.5s ease" }}>
                    <input type="text" className="w-100 background-white" value={searchData} onChange={(e) => setSearchData(e.target.value)} placeholder={t('Zoeken')} />
                </div>
               {loader && <div className="lesson-loader"><Loader /> </div>}
                    {(lessons ?? []).map((lesson) => {
                        return cardChange ? <div className="col-6 py-2 pr-0 d-flex" key={lesson?.id}>
                            <div className="lessons grid w-100">
                                <div className="lessons-image" style={{ position: "relative", backgroundImage: lesson.image_url ? `url("${lesson.image_url}")` : `url("images/placeholder-image.png")` }} >
                                    <Ribbon grade={lesson?.grade?.name} status={lesson?.status} />
                                </div>
                                <div className="lesson-card p-3">
                                    <div>{t('Class')}</div>
                                    <div className="pt-1"><strong>{lesson?.class?.name}</strong></div>
                                    <div className="pt-3">{t('School')}</div>
                                    <div className="pt-1"><strong>{lesson?.class?.school?.name}</strong></div>
                                    <div className="pt-3">{t('Assignment')}</div>
                                    <div className="pt-1"><strong>{lesson.name}</strong></div>

                                    <div className="d-flex justify-content-end">
                                        <Link to={`/lesson-details/${lesson.id}`} className="btn btn-link">
                                            {t("view more")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> : <div className="col-12 py-2 pl-0 d-flex justify-content-end pr-3" key={lesson?.id}>
                            <div className="row lessons list w-100">
                                <div className="lessons-image col-6" style={{ backgroundImage: lesson.image_url ? `url("${lesson.image_url}")` : `url("images/placeholder-image.png")` }} >
                                </div>
                                <div className="lesson-card col-6">
                                    <Ribbon grade={lesson?.grade?.name} status={lesson?.status} />
                                    <div className="pt-4">{t('Class')}</div>
                                    <div className="pt-1"><strong>{lesson?.class.name}</strong></div>
                                    <div className="pt-3">{t('School')}</div>
                                    <div className="pt-1"><strong>{lesson?.class?.school?.name}</strong></div>
                                    <div className="pt-3">{t('Assignment')}</div>
                                    <div className="pt-1"><strong>{lesson.name}</strong></div>
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/lesson-details/${lesson.id}`} className="btn btn-link">
                                            {t("view more")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                    }
            </div>
            {totalItems ?
                (totalItems > 3 ? <div className="cardPagination d-flex justify-content-center mt-4">
                    <Pagination
                        activePage={page}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemsCountPerPage={4}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={3}
                        onChange={(page) => dispatch(fetchPupilsLessons({ pupilId: props.pupilId, searchContent: searchData, page: page }))}
                    />
                </div> : "")
                : (<p className="noLessonFound mt-4">{t('No lessons found')}</p>)
            }

        </div>
    );
}

export default LessonList;
