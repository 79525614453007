import {React, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
// import Errors from "../Errors"
import {useSelector} from "react-redux";

const SchoolForm = ({submit, submitTitle}) => {
  const [t,] = useTranslation()

  const selectedSchool = useSelector(state => state.school.selectedSchool)

  const [name, setName] = useState('')
  const [street, setStreet] = useState('')
  const [houseNumber, setHouseNumber] = useState('')
  const [houseNumberSuffix, setHouseNumberSuffix] = useState('')
  const [postcode, setPostcode] = useState('')
  const [city, setCity] = useState('')
  const [phone, setPhone] = useState('')
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [UrlError, setUrlError] = useState('')
  const [PostError, setPostError] = useState('')
  const [active, setActive] = useState(true)

  useEffect(() => {
    if (selectedSchool) {
      setName(selectedSchool.name || '')
      setStreet(selectedSchool.street || '')
      setHouseNumber(selectedSchool.houseNumber || '')
      setHouseNumberSuffix(selectedSchool.houseNumberSuffix || '')
      setPostcode(selectedSchool.postcode || '')
      setCity(selectedSchool.city || '')
      setPhone(selectedSchool.phoneNumber || '')
      setWebsiteUrl(selectedSchool.websiteUrl || '')
      setActive(selectedSchool.active || true)
    }
  }, [selectedSchool])

  const submitState = (e) => {
    e.preventDefault()
  if(!UrlError && !PostError){ 
     submit(
      name,
      street,
      houseNumber,
      houseNumberSuffix,
      postcode,
      city,
      phone,
      active,
      websiteUrl
    )
   }
  }

  const ValidateWebsite=(value)=>{
     let reg=/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/; 
    setWebsiteUrl(value);
    if(!reg.test(value)) {
      setUrlError('URL was not valid.')
    }else{
      setUrlError('')
    }
  }

  const ValidatePostCode=(value)=>{
  let reg=/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i; 
    setPostcode(value);
   if(!reg.test(value.trim())) {
     setPostError('PostCode was not valid.')
   }else{
     setPostError('')
   }
 }

  return (
    <form onSubmit={submitState}>
      <div className="row">
        <div className="col-12">
        {PostError && <div className="alert alert-danger">{PostError}</div>}
        {UrlError && <div className="alert alert-danger">{UrlError}</div>}
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="name" className="col-form-label">
            {t('Name')}
          </label>
          <input
            id="name"
            type="text"
            className="form-control "
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="phone" className="col-form-label">
            {t('Phone')}
          </label>
          <input
            id="phone"
            type="text"
            className="form-control "
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="website" className="col-form-label">
            {t('Website')}
          </label>
          <input
            id="website"
            type="text"
            className="form-control "
            name="website"
            value={websiteUrl}
            onChange={(e) => ValidateWebsite(e.target.value) }
          />
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label htmlFor="street" className="col-form-label">
            {t('Street')}
          </label>
          <input
            id="street"
            type="text"
            className="form-control "
            name="street"
            required
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-3 mb-4 ">
          <label htmlFor="houseNumber" className="col-form-label">
            {t('House Number')}
          </label>
          <input
            id="houseNumber"
            type="text"
            className="form-control "
            name="houseNumber"
            required
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-3 mb-4 ">
          <label htmlFor="houseNumberSuffix" className="col-form-label">
            {t('Suffix')}
          </label>
          <input
            id="houseNumberSuffix"
            type="text"
            className="form-control "
            name="houseNumberSuffix"
            value={houseNumberSuffix}
            onChange={(e) => setHouseNumberSuffix(e.target.value)}
          />
        </div>
        <div className="col-6 mb-4 ">
          <label htmlFor="place" className="col-form-label">
            {t('Postcode')}
          </label>
          <input
            id="postcode"
            type="text"
            className="form-control "
            name="postcode"
            value={postcode}
            required
            title={t('Valid postcode')}
            onChange={(e) =>  ValidatePostCode(e.target.value)}
          />
        </div>
        <div className="col-6 mb-4 ">
          <label htmlFor="place" className="col-form-label">
            {t('Place')}
          </label>
          <input
            id="city"
            type="text"
            className="form-control "
            name="city"
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="col-12 ">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                name="active"
                checked={active}
                onChange={e => setActive(!active)}
              />
              {t('Active')}
            </label>
          </div>
        </div>
        <div className="col-12 text-right">
          <button type="submit" className="btn btn-secondary">
            {submitTitle}
          </button>
        </div>
      </div>
    </form>
  );
}

export default SchoolForm;
