import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updatePupil } from '../../redux/slices/pupilSlice';

function EditPupilAndUser({ setIsVisible = () => {}, pupil, schoolId, classId }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [reference, setReference] = useState((pupil?.reference || ""));
  const [firstname, setFirstname] = useState((pupil?.user?.first_name || ""));
  const [lastname, setLastname] = useState((pupil?.user?.last_name || ""));

  const handleSubmit = () => {
    dispatch(updatePupil({first_name:firstname,last_name:lastname, id: pupil.id, schoolId : schoolId, reference: reference, resetStates : resetStates}));
  };

  const resetStates = () => {
    setReference("");
    setIsVisible(false);
  };

  return (
    <div className="p-3">
      <h4 className="modal-title d-flex justify-content-center text-capitalize">
        {t(`Update pupil reference`)}
      </h4>
      <div className="row">
            <div className="col-12 mb-2">
                <label htmlFor="firstname" className="col-form-label">
                  {t("First Name")}
                </label>
                <input
                    id="firstname"
                    className="form-control "
                    name="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-2">
                <label htmlFor="lastname" className="col-form-label">
                    {t("Last Name")}
                </label>
                <input
                    id="lastname"
                    className="form-control "
                    name="lastname"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-4">
                <label htmlFor="reference" className="col-form-label">
                    {t("Reference")}
                </label>
                <input
                    id="reference"
                    className="form-control "
                    name="reference"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                />
            </div>
        </div>
      <div className="d-flex justify-content-between mt-3">
        <Button onClick={resetStates}>{t("Close")}</Button>

        <Button onClick={() => handleSubmit()}>{t("Submit")}</Button>
      </div>
    </div>
  );
}
export default EditPupilAndUser;